
import { useDispatch } from "react-redux";
import FetchLoading from "../../../Components/Messages/FetchLoading";
import MainSideBar from "../../../Components/SideBar";
import { apiSlice, useGetAllTenantsQuery, useGetManagersQuery } from "../../../Services/ApiServices";
import { useEffect, useState } from "react";
import { addManagers } from "../../../App/features/managerSlice";
import DataGridTable from "../../../Components/Datagrid";
import { Button } from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import CreateTenant from "./CreateTenant";
import { addTenants } from "../../../App/features/tenantsSlice";

function getFullDate(dateTimeString) {
  try {
    const dateObj = new Date(dateTimeString);
    return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  } catch (error) {
    console.error('Error parsing ISO 8601 date string:', error.message);
    return ''; 
  }
}

export default function ViewTenantsList () {
    const [tempList, setTempList] = useState([]);
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [editData, setEditData] = useState({});
    const [manId, setId] = useState("");
    const [openDelete, setOpenDelete] = useState(false);
    const [name, setName] = useState("");
    const { data, refetch, isLoading, error } = useGetAllTenantsQuery();
    const dispatch = useDispatch();
    const [err, setErr] = useState({error: false, message: ""});
    const [loading, setLoading] = useState(true);

    const updater = async () => {
      dispatch(apiSlice.util.resetApiState());
      const res = await refetch({ forceRefetch: true }).then((response) => {
        if (response.isError) {
          setLoading(false);
          setErr({ error: true, message: response });
        }
        if (response.isSuccess) {
          console.log(response.data.data.tenants);
          dispatch(addTenants(response.data.data.tenants));
          setTempList(response.data.data.tenants.map((item) => {
            return {
              id: item.id,
              name: item.name,
              status: item.status,
              tenantType: item.tenant_type?.title || 'Unknown', // Use optional chaining and provide a fallback value
              created: getFullDate(item.createdAt),
              updated: getFullDate(item.updatedAt),
              actions: item
            };
          }));
          setLoading(false);
        }
      });
    };

    const columns = [
      {
        field: 'name',
        headerName: 'Name',
        width: 150,
        editable: false,
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 150,
        editable: false,
      },
      {
        field: 'tenantType',
        headerName: 'Tenant Type',
        width: 300,
        editable: false,
      },
      {
        field: 'created',
        headerName: 'Created',
        width: 180,
        editable: false,
      },
      {
        field: 'updated',
        headerName: 'Updated',
        width: 180,
        editable: false,
      }
    ];

    useEffect(() => {
      updater();
    }, []);

    const addbtn = (
      <Button style={{ margin: "2%" }} onClick={() => setOpen(true)} variant='outlined' color="success">
        <Add /> Add Tenant
      </Button>
    );

    return (
      <MainSideBar>
        <CreateTenant open={open} closer={setOpen} updater={updater} />
        {loading ? <FetchLoading /> : <DataGridTable title={"Tenants"} options={addbtn} columns={columns} data={tempList} />}
      </MainSideBar>
    );
}