import { useMemo } from 'react';
import './App.css';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { darkThemeOptions, lightThemeOptions } from './Theme/Theme';
import { RouterProvider } from 'react-router-dom';
import router from './Router/Routers';
import { useSelector } from 'react-redux';

function App() {
  const dark = useSelector(state => state.darkMode)
  const theme = useMemo(
    () => createTheme(dark.dark ? darkThemeOptions : lightThemeOptions),
    [dark.dark]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <RouterProvider router={router}/>
    </ThemeProvider>
  );
}

export default App;
