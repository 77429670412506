import { createBrowserRouter, Link, Navigate, redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginSignupForm from "../Pages/SignupPage";
import NotFound from "../Pages/NotFound";
import AdminHome from "../Modules/admin/Dashboard";
import ManagerHome from "../Modules/Manager/DashBoard";
import HeadHome from "../Modules/Head/Dashboard";
import ViewManagersList from "../Modules/Head/Manager/ViewManagers";
import ViewBranches from "../Modules/Head/Branches//ViewBranches";
import AdminBranches from "../Modules/admin/AdBranches";
import HotelInfoLayout from "../Components/HotelInfo";
import ViewRegion from "../Modules/admin/Regions/ViewRegions";
import ClientReview from "../Modules/admin/ClientReview";
import ViewHeads from "../Modules/admin/Heads/ViewHeads";
import ViewAdminsTable from "../Modules/tAdmin/Admins/ViewAdmins";
import ViewForm from "../Modules/superAdmin/Forms/FormsLayout";
import EditForm from "../Modules/superAdmin/Forms/EditForm";
import CreateForm from "../Modules/superAdmin/Forms/AdminCreateForm";
import TenantAdminHome from "../Modules/tAdmin/DashBoard";
import AllUserTable from "../Modules/tAdmin/AllUsers";
import AllBranches from "../Modules/tAdmin/BranchesTable";
import AllRegions from "../Modules/tAdmin/RegionsTable";
import LinkCard from "../Components/LinkCard";
import UnAuthorised from "../Pages/UnAuthenticated";
import ClientReviewForm from "../Components/ClientReviewForm";
import SuperAdmin from "../Modules/superAdmin/Dashboard";
import TenantType from "../Modules/superAdmin/TenantType/TenantType";
import Sentiments from "../Modules/superAdmin/Sentiment/Sentiments";
import ViewTenantsList from "../Modules/superAdmin/Tenants/ViewTenants";
import ViewFormList from "../Modules/superAdmin/FormList/FormList";


function SysAdminRoute({ children }) {
  let role = "";
  const isAuthenticated = Boolean(sessionStorage.getItem("token"));
  const state = sessionStorage.getItem("state");
  if (state) {
    role = JSON.parse(state)?.user?.role || "";
  }
  return isAuthenticated ? (role === "SysAdmin" ? children : <Navigate to="/unauthorised" />) : <Navigate to="/login" />;
}

function ManagerRoute({ children }) {
  let role = "";
  const isAuthenticated = Boolean(sessionStorage.getItem("token"));
  const state = sessionStorage.getItem("state");
  if (state) {
    role = JSON.parse(state)?.user?.role || "";
  }
  return isAuthenticated ? (role === "Manager" ? children : <Navigate to="/unauthorised" />) : <Navigate to="/login" />;
}

function AdminRoute({ children }) {
  let role = "";
  const isAuthenticated = Boolean(sessionStorage.getItem("token"));
  const state = sessionStorage.getItem("state");
  if (state) {
    role = JSON.parse(state)?.user?.role || "";
  }
  return isAuthenticated ? (role === "Admin" ? children : <Navigate to="/unauthorised" />) : <Navigate to="/login" />;
}

function TadminRoute({ children }) {
  let role = "";
  const isAuthenticated = Boolean(sessionStorage.getItem("token"));
  const state = sessionStorage.getItem("state");
  if (state) {
    role = JSON.parse(state)?.user?.role || "";
  }
  return isAuthenticated ? ((role === "TenantAdmin")? children: <Navigate to="/unauthorised" />) : <Navigate to="/login" />;
}

function HeadRoute({ children }) {
  let role = "";
  const isAuthenticated = Boolean(sessionStorage.getItem("token"));
  const state = sessionStorage.getItem("state");
  if (state) {
    role = JSON.parse(state)?.user?.role || "";
  }
  return isAuthenticated ? (role === "Head" ? children : <Navigate to="/unauthorised" />) : <Navigate to="/login" />;
}

const router = createBrowserRouter([
    {
        path: "/adminHome",
        element:  <AdminRoute><AdminHome/></AdminRoute>,
    },
    {
        path: "/managerHome",
        element: <ManagerRoute><ManagerHome/></ManagerRoute>
    },
    {
        path: "/generateLink",
        element:  <ManagerRoute><LinkCard/></ManagerRoute>
    },
    {
        path: "/editform/:formsId",
        element:  <SysAdminRoute><EditForm/></SysAdminRoute>
    },
    {
        path: "/createForm/:formsId",
        element: <SysAdminRoute><CreateForm/></SysAdminRoute>
    },
    {
        path: `/viewForms/:formsId`,
        element:  <SysAdminRoute><ViewForm/></SysAdminRoute>
    },
    {
        path: "/viewAdmins",
        element:  <TadminRoute><ViewAdminsTable/></TadminRoute>
    },
    {
        path: "/tadminHome",
        element:  <TadminRoute><TenantAdminHome/></TadminRoute>
    },
    {
        path: "/allusers",
        element:  <TadminRoute><AllUserTable/></TadminRoute>
    },
    {
        path: "/allbranches",
        element:  <TadminRoute><AllBranches/></TadminRoute>
    },
    {
        path: "/allregions",
        element:  <TadminRoute><AllRegions/></TadminRoute>
    },
    {
        path: "/viewHeads",
        element:  <AdminRoute><ViewHeads/></AdminRoute>
    },
    {
        path: "/reviews",
        element:  <AdminRoute><ClientReview/></AdminRoute>
    },
    {
        path: "/details",
        element:  <HotelInfoLayout/>
    },
    {
        path: "/viewRegion",
        element:  <AdminRoute><ViewRegion/></AdminRoute>
    },
    {
        path: "/adBranches",
        element:  <AdminRoute><AdminBranches/></AdminRoute>,
    },
    {
        path: "/unauthorised",
        element:  <UnAuthorised/>
    },
    {
        path: "/headHome",
        element:  <HeadRoute><HeadHome/></HeadRoute>
    },
    {
        path: "/viewManagers",
        element:  <HeadRoute><ViewManagersList/></HeadRoute>
    },
    {
        path: "/viewBranches",
        element:  <HeadRoute><ViewBranches/></HeadRoute>
    },
    {
        path: "/client/:branchID",
        element: <ClientReviewForm/>
    },
    {
        path: "/",
        element: <LoginSignupForm/>
    },
    {
        path: "/login",
        element: <LoginSignupForm/>
    },
    {
        path: "/superAdminHome",
        element: <SysAdminRoute><SuperAdmin/></SysAdminRoute>
    },
    {
        path: "/formList",
        element: <SysAdminRoute><ViewFormList/></SysAdminRoute>
    },
    {
        path: "/viewTenants",
        element: <SysAdminRoute><ViewTenantsList/></SysAdminRoute>
    },
    {
        path: "/tenanttype",
        element: <TenantType/>
    },
    {
        path: "/sentiments",
        element: <Sentiments/>
    },
    {
        path:"/*",
        element:  <NotFound/>
    }
])

export default router;