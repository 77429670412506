import { Button, Card, Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { apiSlice, useGetDashboardsQuery } from "../../Services/ApiServices";
import { useEffect, useState } from "react";
import FetchLoading from "../../Components/Messages/FetchLoading";
import { addDash } from "../../App/features/dashboadSlice";
import { useNavigate } from "react-router-dom";
import DashHeader from "./DashHead";
import MainSideBar from "../../Components/SideBar";

export default function TenantAdminHome() {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const dashboard = useSelector(state => state.dashboard);
  const firstName = useSelector(state => state.user.firstName);
  const lastName = useSelector(state => state.user.lastName);
  const [err, setErr] = useState({ error: false, message: "" });
  const [loading, setLoading] = useState(true);
  const { data, refetch, isLoading, error } = useGetDashboardsQuery();

  const updater = async () => {
    dispatch(apiSlice.util.resetApiState());
    try {
      const response = await refetch({ forceRefetch: true });
      setLoading(false);

      if (response.error) {
        setErr({ error: true, message: response.error.message || 'An error occurred' });
      } else if (response.data) {
        console.log(response.data.data);
        dispatch(addDash(response.data.data));
      }
    } catch (err) {
      console.error("Unexpected error:", err);
      setErr({ error: true, message: err.message || 'An unexpected error occurred' });
      setLoading(false);
    }
  };

  useEffect(() => {
    updater();
  }, []);

  if (loading) {
    return <FetchLoading />;
  }

  if (err.error) {
    return <div>Error: {err.message}</div>;
  }

  return (
    <MainSideBar>
      <DashHeader/>
      {/* Your component content here */}
    </MainSideBar>
  );
}