import { useSelector } from "react-redux"
import MainSideBar from "../../Components/SideBar"
import DataGridTable from "../../Components/Datagrid"
import { Button } from "@mui/material"
import { ArrowBack } from "@mui/icons-material"

export default function AllUserTable() {
    const data = useSelector(state=>state.dashboard.users)
     const columns = [
  {
    field: 'firstName',
    headerName: 'First name',
    width: 250,
    editable: false,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 250,
    editable: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 350,
    editable: false,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 250,
    editable: false,
  },
]
const filter = () =>{
            return data.map((item)=>{
                return {...item, role: item.roles[0].title}
            })
} 

const goback = <Button variant='outlined' style={{ margin:"2%" }} onClick={() =>{ window.history.go(-1); return false;}}><ArrowBack/> Go Back</Button>
return (
  <MainSideBar>
    <DataGridTable data={filter()} columns={columns} title={"All Users"} options={goback} />
  </MainSideBar>
)

}