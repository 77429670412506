import { CloseOutlined } from "@mui/icons-material"
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"

export default function ViewSentiment({open, data, closer}) {
    return (
        <Dialog open={open} PaperProps={{ component: 'form' }}>
        <div style={{ padding:"1%" }}>
        <div style={{ width: "100%", display: "flex", justifyContent: "right" }}>
        <div style={{ width:"90%" }}>
        <DialogTitle>Sentiment Detail</DialogTitle>
        </div>
        <div>
          <IconButton color='error' >
            <CloseOutlined style={{ margin: "5px" }} onClick={()=>{closer((prev)=>{return false})}} />
          </IconButton>
        </div>
        </div>
        <DialogContent>
                <>
                <Typography color={"primary"}>Title : {data.title}</Typography>
                <Typography color={"primary"}>Prompt : {data.prompt}</Typography>
                <Typography color={"primary"}>Sentiment Type : {data.tenant_types}</Typography>
                </>
        </DialogContent>
        </div>
      </Dialog>
    )
}